<template>
  <v-container class="app-page-home mt-n10">
    <div v-if="has_ext">
      <v-tabs color="orange accent-4" grow :show-arrows="false">
        <v-tab>Noticias de GNS</v-tab>
        <v-tab>Noticias de la Empresa</v-tab>

        <v-tab-item class="py-4">
          <news-list mode="gns"></news-list>
        </v-tab-item>
        <v-tab-item class="py-4">
          <news-list-ext mode="other"></news-list-ext>
        </v-tab-item>
      </v-tabs>
    </div>

    <v-container v-else>
      <news-list mode="gns"></news-list>
    </v-container>
  </v-container>
</template>

<script>
import NewsList from "@/components/noticias/List.vue"
import NewsListExt from "@/components/noticias/ListExt.vue"
import { mapGetters } from "vuex"

export default {
  name: "noticias",
  components: {
    NewsList, NewsListExt
  },
  data() {
    return {
      loading: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      tab: null,
      pager: {
        loading: true,
        page: 1,
        records: [],
      },
      pager_ext: {
        loading: true,
        page: 1,
        records: [],
      },
      has_ext: false,
    };
  },

  created() {
    this.has_ext = this.user.company_news != ''
  },

  watch: {},

  computed: {
    ...mapGetters(["user"]),
  },

  mounted() {
    // this.$store.commit("setNavigationDrawer", true)
    // this.$store.commit("setToolbarTitle", "Noticias Digitales")
    // this.$store.commit("stopProcessing")
    // this.$store.commit("setBackButton", false)
    // this.$store.commit("setActiveNav", 1)
    this.$store.commit("setNavigationDrawer", true);
    this.$store.commit("setActiveNav", 1);
  },
};
</script>

<style scoped>
.v-navigation-drawer .nombre-usuario {
  font-size: 16px;
}
</style>
