<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="12" sm="6" md="4" lg="4" v-for="i in 10" :key="mode + '-' + i">
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="false"
          type="article"
          :tile="false"
          class="mx-auto"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <v-col v-for="novedad in pager.records" :key="novedad.id" cols="12" sm="6" md="4" lg="4">
        <v-card 
          @click="navigate(novedad)"
          class="card-noticia mx-auto"
          color="forma"
        >
          <v-img
            class="card-noticia-img white--text align-end"
            height="190px"
            :src="novedad.image"
            gradient="to bottom left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          >
            <div class="overlay"></div>
            <h1 class="card-noticia-title">{{ novedad.title }}</h1>
            <p class="card-noticia-subtitle">{{ novedad.subtitle }}</p>
          </v-img>
        </v-card>
      </v-col>

      <v-col cols="12">
        <div class="mt-4 d-flex justify-center">
          <v-btn
            v-if="pager.has_pagination"
            class="btn-recibos"
            :loading="pager.loading"
            @click="moreLists()"
          >Ver más</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { getNovedades } from "@/api/core";

export default {
  name: "news-list-ext",

  props: {
    mode: String,
  },

  data() {
    return {
      loading: true,
      pager: {
        records: [],
        loading: false,
        page: 1,
        count: 0,
        total: 0,
        has_pagination: false,
      },
    };
  },

  created() {},

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      this.pager.loading = true;

      const data = await getNovedades(this.pager.page, this.mode);

      if (data) {
        this.pager.records = this.pager.records.concat(data);
        this.pager.page = Object.prototype.hasOwnProperty.call(data, 'pagina') ? data.pagina : 1;
        this.pager.total = Object.prototype.hasOwnProperty.call(data, 'paginas') ? data.paginas : 1;
        this.pager.count = Object.prototype.hasOwnProperty.call(data, 'cant') ? data.cant : data.length;
        this.pager.has_pagination = data.length > 0;
      }

      this.loading = false;
      this.pager.loading = false;
    },

    moreLists() {
      this.pager.page++;
      this.getList();
    },

    navigate(record) {
      window.open(record['link'], '_blank')
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
:root {
  @import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
}

.card-noticia {
  border-radius: 10px !important;
  height: 190px;
  transition: all 200ms ease;
}

.card-noticia:hover {
  box-shadow: 0px 8px 20px rgba(29, 14, 40, 0.4) !important;
  transform: scale(1.02);
}
.card-noticia-img {
  padding: 20px 30px;
}
.card-noticia-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113%;
  color: #f5f7f6;
}
.card-noticia-subtitle {
  padding-top: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #f5f7f6;
}

.social-icon {
  color: white;
  font-size: 14px;
  padding-left: 14px;
}

.card-noticia-category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.btn-recibos {
  background: #ff7f04 !important;
  box-shadow: none;
  border-radius: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform:lowercase;
  color: #ffffff !important;
  height: 41px;
  width: 233px;
}
</style>