<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="12" sm="6" md="4" lg="4" v-for="i in 10" :key="mode + '-' + i">
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="false"
          type="article"
          :tile="false"
          class="mx-auto"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <v-col v-for="novedad in pager.records" :key="novedad.id" cols="12" sm="6" md="4" lg="4">
        <v-card
          class="card-noticia mx-auto"
          :href="novedad['article-link']"
          target="_blank"
          :color="novedad.color"
        >
          <v-img
            class="card-noticia-img white--text align-end"
            height="190px"
            :src="novedad.cabezal"
            gradient="to bottom left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          >
            <div class="overlay"></div>
            <h1 class="card-noticia-title">{{ novedad.titulo }}</h1>
            <p class="card-noticia-subtitle">{{ novedad.subtitulo }}</p>
            <v-row>
              <v-col cols="6" class="pa-0">
                <v-chip
                  class="ma-2 card-noticia-category"
                  small
                  :color="novedad.color"
                  text-color="white"
                >{{ novedad.tags[0] }}</v-chip>
              </v-col>
              <v-col cols="6" class="pa-0 text-right">
                <div class="card-noticia-social-links">
                  <v-icon class="social-icon">fab fa-facebook-f</v-icon>
                  <v-icon class="social-icon">fab fa-twitter</v-icon>
                  <v-icon class="social-icon">fab fa-linkedin</v-icon>
                  <v-icon class="social-icon">fab fa-whatsapp</v-icon>
                </div>
                <!-- <div class="caption mb-0 text-grey">Creado el {{ novedad.fecha }}</div> -->
              </v-col>
            </v-row>
          </v-img>
        </v-card>
      </v-col>

      <v-col cols="12">
        <div class="mt-4 d-flex justify-center">
          <v-btn
            v-if="pager.has_pagination"
            class="btn-recibos"
            :loading="pager.loading"
            @click="moreLists()"
          >Ver más</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { getNovedades } from "@/api/core";

export default {
  name: "news-list",

  props: {
    mode: String,
  },

  data() {
    return {
      loading: true,
      pager: {
        records: [],
        loading: false,
        page: 1,
        count: 0,
        total: 0,
        has_pagination: false,
      },
    };
  },

  created() {},

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      this.pager.loading = true;

      const data = await getNovedades(this.pager.page, this.mode);

      if (data.articulos) {
        this.pager.records = this.pager.records.concat(data.articulos);
        this.pager.page = data.pagina;
        this.pager.total = data.paginas;
        this.pager.count = data.cant;
        this.pager.has_pagination = data.articulos.length > 0;
      }

      this.loading = false;
      this.pager.loading = false;
    },

    moreLists() {
      this.pager.page++;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
:root {
  @import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
}

.card-noticia {
  border-radius: 10px !important;
  height: 190px;
  transition: all 200ms ease;
}

.card-noticia:hover {
  box-shadow: 0px 8px 20px rgba(29, 14, 40, 0.4) !important;
  transform: scale(1.02);
}
.card-noticia-img {
  padding: 20px 30px;
}
.card-noticia-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113%;
  color: #f5f7f6;
}
.card-noticia-subtitle {
  padding-top: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #f5f7f6;
}

.social-icon {
  color: white;
  font-size: 14px;
  padding-left: 14px;
}

.card-noticia-category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.btn-recibos {
  background: #ff7f04 !important;
  box-shadow: none;
  border-radius: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform:lowercase;
  color: #ffffff !important;
  height: 41px;
  width: 233px;
}
</style>